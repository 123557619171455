'use strict';

import { throttle } from './modules/_fn';

(function() {
	/* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}

	window.addEventListener('load', getWinSet);
	window.addEventListener('resize', function () {
		getWinSet();
	});
	/* ---------------------------------------- [END] Windows Setting */

	/* ---------------------------------------- [START] 取得裝置判斷 */
	// 取得裝置判斷
	let isMobile = false;
	let isTablet = false;
	let isPhone = false;

	const deviceDetect = function () {
		// IsPhone
		isPhone = ww <= 640;

		// IsMobile
		// 防止測試時一直用開發者工具Resize出現Bug
		isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		if (isMobile) html.classList.add('is-mobile');
		else html.classList.remove('is-mobile');

		// IsTablet
		if (navigator.userAgent.match(/Android/i)) {
			if (!navigator.userAgent.match(/Mobile/i)) {
				isTablet = true;
			}
		} else if (navigator.userAgent.match(/BlackBerry|iPad|Opera Mini|IEMobile/i)) {
			isTablet = true;
		}
	};

	deviceDetect();
	window.addEventListener('resize', throttle(deviceDetect, 50, 100));
	/* ---------------------------------------- [END] 取得裝置判斷 */

	/* ---------------------------------------- [START] 判斷browser */
	var ua = navigator.userAgent;
	var browser = {
		isChrome: /chrome/i.test(ua),
		isFirefox: /firefox/i.test(ua),
		isSafari: /safari/i.test(ua),
		isIE: /msie/i.test(ua) || /trident/i.test(ua),
		isEdge: /edge/i.test(ua),
	};

	// 修正數值browser
	if (browser.isChrome) browser.isSafari = false;
	if (browser.isEdge) {
		browser.isChrome = false;
		browser.isSafari = false;
	}

	var browserIs;
	for (var key in browser) {
		if (browser[key]) {
			browserIs = key.split('is')[1];
			// 增加Class
			document.documentElement.classList.add(browserIs.toLowerCase());
			document.documentElement.browser = browserIs;
			break;
		}
	}
	browser.is = browserIs;

	// ----------------------------
	// 判斷 裝置
	// iOS
	var isiOS = ua.toLowerCase().match(/(iphone|ipod|ipad)/);
	isiOS && html.classList.add('ios');
	/* ---------------------------------------- [END] 判斷browser */

	/* ---------------------------------------- [START] 鎖右鍵 */
	if (location.hostname !== "localhost") {
		const $body = document.querySelector('body');
		$body.addEventListener("contextmenu", (e) => {e.preventDefault()});
		$body.addEventListener("selectstart", (e) => {e.preventDefault()});
		$body.addEventListener("dragstart", (e) => {e.preventDefault()});
	}
	/* ---------------------------------------- [END] 鎖右鍵 */

	/* ---------------------------------------- [START] 選單下滑更改樣式 */
	const doc = document.documentElement;
	const header = document.querySelector('#hd');
	// const footer = document.querySelector('#ft');
	const headerClassScroll = 'is-collapse';

	function headerScrollHandler() {
		const windowScrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
		if (windowScrollTop > 60) {
			header.classList.add(headerClassScroll);
		} else {
			header.classList.remove(headerClassScroll);
		}
	}

	window.addEventListener('scroll', headerScrollHandler);
	window.addEventListener('load', headerScrollHandler);
	/* ---------------------------------------- [END] 選單下滑更改樣式 */

	/* ---------------------------------------- [START] 漢堡選單 */
	$(document).on('click', '.js-mb-nav', function () {
		const $el = $(this).parent();

		if (ww < 1024) {
			if ($el.hasClass('is-active')) {
				$el.removeClass('is-active');
			} else {
				$el.addClass('is-active');
			}
		}
	});

	$(document).on('click', '.js-hd-switch', function () {
		if ($('.header_bottom').hasClass('is-open')) {
			$('.header_bottom').removeClass('is-open');
		} else {
			$('.header_bottom').addClass('is-open');
		}
	});
	/* ---------------------------------------- [END] 漢堡選單 */

	/* ---------------------------------------- [START] ScrollTriggerAni */
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	let aniSec = document.querySelectorAll('.js-ani');
	const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用

	function scrollTriggerAni() {
		for (var i = 0; i < aniSec.length; i++) {
			if (isInViewport(aniSec[i])) {
				aniSec[i].classList.add('is-animated');
			}
		}

		cleanTrigger();

		/* If load all of the item, stop Listener */
		if (aniSec.length === 0) {
			window.addEventListener('scroll', scrollTriggerAniThrottle);
		}
	}

	function cleanTrigger() {
		aniSec = Array.prototype.filter.call(aniSec, function (ani) {
			return !ani.classList.contains('is-animated');
		});
	}

	function isInViewport(el) {
		const rect = el.getBoundingClientRect();
		const isVisible = el.offsetHeight !== 0;

		return (
			isVisible &&
			rect.bottom >= 0 &&
			rect.right >= 0 &&
			rect.top + 100 <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.left <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	window.addEventListener('load', scrollTriggerAni);
	window.addEventListener('scroll', scrollTriggerAniThrottle);
	/* ---------------------------------------- [END] ScrollTriggerAni */
})();
